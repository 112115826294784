import React, { useEffect, createRef } from "react"
import LayoutHrbridReport from "../components/layouthybridreport"
import NavigationTeam from "../components/navigationteam"
import ReportFormChennaiManagedOffice from "../components/ReportFormChennaiManagedOffice"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import "../styles/hybrid.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import SEOHeader from "../components/seo-header"
import "lazysizes"
import HybridImage from "../img/people-meeting-seminar-office-concept.jpg"
export default function HybridReport() {
  return (
    <div>
      <SEOHeader
        title="A Comprehensive Report on How Chennai's Coworking Industry is Evolving & Upgrading
"
        description="Coworking & Managed Offices A Comprehensive Report on How Chennai's Coworking Industry is Evolving & Upgrading Industry trends & real-time data included"
        socialURL="https://assets.gofloaters.com/chennai-managed-offices-report/chennai-managed-offices-report.jpg"
        pinterest="true"
      ></SEOHeader>
      <Helmet></Helmet>
      <LayoutHrbridReport>
        <div className="hybridreport">
          <NavigationTeam />
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
            </div>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div className="TeamTextContent text-center">
                  <br></br>
                  <br></br>
                  <h3
                    style={{
                      lineHeight: "1",
                      padding: "0",
                      marginBottom: "15px",
                    }}
                  >
                    Coworking & Managed Offices
                  </h3>
                  <h2
                    style={{
                      lineHeight: "1",
                      padding: "0",
                      marginBottom: "15px",
                      fontWeight: "600",
                    }}
                  >
                    A Comprehensive Report on
                  </h2>
                  <h1
                    style={{
                      fontSize: "3.2em",
                      color: "#4f9fc8",
                      marginBottom: "15px",
                      padding: "0",
                      lineHeight: "1",
                    }}
                  >
                    How Chennai's Coworking Industry is Evolving & Upgrading
                  </h1>
                  <p>Industry trends & real-time data included</p>
                  <br></br>
                  <a
                    onClick={() => scrollTo("#signup")}
                    className="teamGetStarted"
                    style={{ background: "rgb(79, 159, 200)", color: "#fff" }}
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <br></br>
                <img
                  data-src="https://assets.gofloaters.com/chennai-managed-offices-report/chennai-managed-offices-report.png"
                  alt="How Chennai's Coworking Industry is Evolving & Upgrading"
                  className="lazyload img-responsive"
                ></img>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>

        <div style={{ padding: "10px 0", background: "#fff" }}>
          <div className="container">
            <div className="row  teamPadding TeamsWorkFromHome"></div>
          </div>
        </div>

        <div style={{ background: "#fff" }}>
          <div>
            <div className="container">
              <ReportFormChennaiManagedOffice />
            </div>
          </div>
        </div>

        {/* <div style={{ background: "#fff", padding: "60px 0" }}>
          <div className="container">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-1 col-2">
                <img
                  src="https://assets.gofloaters.com/worqpass/center-arrows.svg"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-6 col-8 text-center">
                <h2 className="readyToHybrid">
                  Ready for<br></br> Hybrid-Remote for Teams?
                </h2>
              </div>
              <div className="col-md-1 col-2">
                {" "}
                <img
                  src="https://assets.gofloaters.com/worqpass/center-arrows.svg"
                  className="img-responsive rightimg"
                ></img>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div> */}
      </LayoutHrbridReport>
    </div>
  )
}
